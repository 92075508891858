import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo3.avif';

export default function Maintenance() {
  return (
    <div className='maintenance'>
      <img className='maintenance__logo' src={logo} alt='Logo of ToinePCs' />
      <div className='maintenance__text'>  
        <h1 className='maintenance__title'>ToinePCs Coming Soon</h1>
        <h2 className='maintenance__subtitle'>The website is under renovation! We'll see you soon at ToinePCs!</h2>
        <p className='maintenance__contact'>
          If you need to get in touch: 
          <Link className='maintenance__link' to='tel:+16476258085'>
            647-625-8085
          </Link>
        </p>
      </div>
    </div>
  );
}
