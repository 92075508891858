import './App.css';
import { useEffect } from 'react';
import Homepage from './pages/Homepage';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/DefaultLayout';
import Shop from './pages/Shop';
import PCDetails from './pages/PCDetails';
import Login from './pages/Login';
import Backoffice from './pages/Backoffice';
import BackofficeAddPC from './pages/BackofficeAddPC';
import BackofficeEditPC from './pages/BackofficeEditPC';
import ReactModal from 'react-modal';

import ReactGA from 'react-ga';
import Maintenance from './pages/Maintenance';
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  ReactModal.setAppElement('#root');

  useEffect(()=> {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route exact path= '/' element={<Maintenance />} />
        {/* <Route exact path='/' element={<DefaultLayout />}>
          <Route index element={<Homepage />} /> 
          <Route path='/collection' element={<Shop />} />
          <Route path='/collection/:id' element={<PCDetails />} />
          <Route path='/adminlogin' element={<Login />} />
          <Route path='/backoffice' element={<Backoffice />} />
          <Route path='/backoffice/newpc' element={<BackofficeAddPC />} />
          <Route path='/backoffice/pc/:id' element={<BackofficeEditPC />} />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
